<template>
  <Top />
  <div class="main" v-loading="loading">
    <transaction-navbar :title="lang.v.myOrder" />
    <el-tabs
      v-model="activeName"
      class="demo-tabs"
      @tab-change="handleChange"
      stretch
    >
      <el-tab-pane :label="lang.v.myPendingOrder" name="myPendingOrder">
        <div class="menu">
          <el-dropdown @command="onChangeOrderType" class="menu-left">
            <span class="payment-method">
              {{ lang.v.orderType }}
              <el-icon><ArrowDown /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>{{ lang.v.whole }}</el-dropdown-item>
                <el-dropdown-item
                  v-for="item in OrderType"
                  :key="item.id"
                  :command="item.id"
                >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="list">
          <div v-for="item in PeddingRecord.transactionList" :key="item">
            <MyPeddingOrder :data="item" :timeLimit="PeddingRecord.timeLimit" />
          </div>
        </div>
        <div class="page" v-show="PeddingRecord.total != 0">
          <van-pagination
            v-model="PeddingRecord.page"
            :total-items="PeddingRecord.total"
            :items-per-page="4"
            @change="onChangePage"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="lang.v.myTransaction" name="myOverOrder"
      ><div class="menu">
         <el-dropdown @command="onChangeOrderTypeOver" class="menu-left">
           <span class="payment-method">
             {{ lang.v.orderType }}
             <el-icon><ArrowDown /></el-icon>
           </span>
           <template #dropdown>
             <el-dropdown-menu>
               <el-dropdown-item>{{ lang.v.whole }}</el-dropdown-item>
               <el-dropdown-item
                 v-for="item in OrderType"
                 :key="item.id"
                 :command="item.id"
               >{{ item.name }}</el-dropdown-item
               >
             </el-dropdown-menu>
           </template>
         </el-dropdown>
         <el-dropdown @command="onChangeOrderStatusOver">
           <span class="payment-method">
             {{ lang.v.orderStatus }}
             <el-icon><ArrowDown /></el-icon>
           </span>
           <template #dropdown>
             <el-dropdown-menu>
               <el-dropdown-item>{{ lang.v.whole }}</el-dropdown-item>
               <el-dropdown-item
                 v-for="item in OrderStatusMap"
                 :key="item.id"
                 :command="item.id"
               >{{ item.name }}</el-dropdown-item
               >
             </el-dropdown-menu>
           </template>
         </el-dropdown>
       </div>
        <div class="list">
          <div v-for="item in OverRecord.transactionList" :key="item">
            <MyOverOrder :data="item" :timeLimit="PeddingRecord.timeLimit" />
          </div>
        </div>
        <div class="page" v-show="OverRecord.total != 0">
          <van-pagination
            v-model="OverRecord.page"
            :total-items="OverRecord.total"
            :items-per-page="3"
            @change="onChangeOrderPage"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import { ArrowDown } from '@element-plus/icons-vue';
import {
  fetchTransactionMyOverOrder,
  fetchTransactionMyPendingOrder,
  fetchTransactionServePayment,
  fetchTransactionServeTime,
} from '@/api/fetcher';
import dayjs from 'dayjs';
import Top from '@/components/Top/top.vue';
import MyOverOrder from '@/components/MyOverOrder/index.vue';
import MyPeddingOrder from '@/components/MyPeddingOrder/index.vue';
import TransactionNavbar from '@/components/TransactionNavbar/index.vue';
import { lang } from '@/lang';
const activeName = ref('myPendingOrder');

const handleChange = tab => {
  if (tab == 'myPendingOrder') {
    getTransactionPeddingOrder();
  } else if (tab == 'myOverOrder') {
    getTransactionOverOrder();
  }
};

const getLimitTime = async () => {
  const data = await fetchTransactionServeTime();
  if (data) {
    OverRecord.timeLimit = data;
    PeddingRecord.timeLimit = data;
  }
};
// 挂单类型映射
const OrderType = [
  {
    id: 1,
    name: lang.v.buyPoints,
  },
  {
    id: 2,
    name: lang.v.sellPoints,
  },
];

// 挂单状态
const OrderStatus = [
  {
    id: 1,
    name: lang.v.release,
  },
  {
    id: 2,
    name: lang.v.locking,
  },
  {
    id: 3,
    name: lang.v.end,
  },
  {
    id: 4,
    name: lang.v.removeFromShelves,
  },
];

//订单状态
const OrderStatusMap = [
  {
    id: 0,
    name: lang.v.establish,
    color: '#ED931E',
  },
  {
    id: 1,
    name: lang.v.approachingTimeout,
    color: '#ED931E',
  },
  {
    id: 2,
    name: lang.v.overtime,
    color: '#DC4343',
  },
  {
    id: 3,
    name: lang.v.cancel,
    color: '#858C9F',
  },
  {
    id: 4,
    name: lang.v.buyerConfirmed,
    color: '#ED931E',
  },
  {
    id: 5,
    name: lang.v.sellerConfirmed,
    color: '#ED931E',
  },
  {
    id: 6,
    name: lang.v.successfulTrade,
    color: '#858C9F',
  },
];
// loading
const loading = ref(true);
// 挂单数据
const PeddingRecord = reactive({
  page: 1,
  /** 列表 */
  transactionList: [],
  /** 单总数 */
  total: 0,
  /** 商品类型 1：买 2：卖 */
  type: '',
  /** 支付方式 */
  payWay: [],
  //订单状态
  status: '',
  // 支付方式名称
  payWayName: '',
  //超时时间
  timeLimit: 10,
});
// 完单数据
const OverRecord = reactive({
  page: 1,
  /** 列表 */
  transactionList: [],
  /** 单总数 */
  total: 0,
  /** 商品类型 1：买 2：卖 */
  type: '',
  /** 支付方式 */
  //订单状态
  status: '',
  payWay: null,
  // 支付方式名称
  payWayName: '',
  //超时时间
  timeLimit: 10,
});
//获取挂单数据
const getTransactionPeddingOrder = async () => {
  loading.value = true;
  const res = await fetchTransactionMyPendingOrder({
    type: PeddingRecord?.type || '',
    page: '1',
    limit: '4',
    status: PeddingRecord?.status || '',
  }).finally(() => {
    loading.value = false;
  });
  PeddingRecord.total = res.total;
  PeddingRecord.transactionList = res.list;
  PeddingRecord.transactionList = PeddingRecord.transactionList.reduce(
    (acc, item1) => {
      const match = PeddingRecord.payWay.find(
        item2 => item2?.id == item1.payWay
      );
      if (match) {
        item1.payWayName = match.name;
      }
      item1.createTime = dayjs(item1.createTime).format('YYYY/MM/DD HH:mm:ss');
      acc.push(item1);
      return acc;
    },
    []
  );
};
//挂单页码切换函数
const onChangePage = id => {
  PeddingRecord.page = id;
  getTransactionPeddingOrder();
};

//订单页切换
const onChangeOrderPage = id => {
  OverRecord.page = id;
  getTransactionOverOrder();
};
//获取交易数据
const getTransactionOverOrder = async () => {
  loading.value = true;
  const res = await fetchTransactionMyOverOrder({
    page: OverRecord.page,
    limit: '3',
    type: OverRecord.type,
    status: OverRecord.status,
  }).finally(() => {
    loading.value = false;
  });
  if (res) {
    OverRecord.total = res.total;
    OverRecord.transactionList = res.list;
  }
  OverRecord.transactionList = OverRecord.transactionList.reduce(
    (acc, item1) => {
      const match = OverRecord.payWay.find(item2 => item2?.id == item1.payWay);
      if (match) {
        item1.payWayName = match.name;
      }
      item1.createTime = dayjs(item1.createTime).format('YYYY/MM/DD HH:mm:ss');
      acc.push(item1);
      return acc;
    },
    []
  );
  OrderStatusMapHandle(OverRecord.transactionList, OrderStatusMap);
};

//订单状态数据处理
const OrderStatusMapHandle = (list, Map) => {
  list = list.reduce((acc, item1) => {
    const match = Map.find(item2 => item2?.id == item1.status);
    if (match) {
      item1.statusName = match.name;
      item1.payWayColor = match.color;
    }
    acc.push(item1);
    return acc;
  }, []);
};
// 获取支付方式
const getTransactionPayment = async () => {
  const res = await fetchTransactionServePayment();
  if (res) {
    PeddingRecord.payWay = res;
    OverRecord.payWay = res;
  }
};
// 订单
const onChangeOrderType = id => {
  PeddingRecord.type = id;
  getTransactionPeddingOrder();
};
//订单状态
const onChangeOrderStatus = id => {
  PeddingRecord.status = id;
  getTransactionPeddingOrder();
};
//交易订单类型
const onChangeOrderTypeOver = id => {
  OverRecord.type = id;
  getTransactionOverOrder();
};
//交易订单状态
const onChangeOrderStatusOver = id => {
  OverRecord.status = id;
  getTransactionOverOrder();
};
onMounted(() => {
  getTransactionPayment();
  getTransactionPeddingOrder();
  getLimitTime();
});
</script>

<style lang="less" scoped>
.main {
  padding: 0.14rem;
  .demo-tabs > .el-tabs__content {
    padding: 0;
    color: #6b778c;
    font-weight: 600;
  }
  .menu {
    height: 0.16rem;
    font-weight: 600;
    font-size: 0.12rem;
    color: #000000;
    line-height: 0.16rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.16rem;
    .payment-method {
      margin-right: 0.04rem;
      font-weight: 600;
      font-size: 0.12rem;
      color: #000000;
      line-height: 0.16rem;
    }
    :deep(.el-tooltip__trigger:focus-visible) {
      outline: unset;
    }
  }
  .menu-left {
    margin-right: 0.2rem;
  }
}
:deep(.el-tabs__item.is-active) {
  color: #ed931e;
}
:deep(.el-tabs__active-bar) {
  background-color: #ed931e;
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.1rem;
}
</style>
